import "./sliders.scss"
import React, { useEffect, useState } from "react"
import "swiper/css"
import "swiper/css/navigation"

// import required modules
import { Autoplay, Navigation } from "swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import imgOne from "../../asst/slider-img-7.png"
import imgTwo from "../../asst/happy-customer-1.png"
import imgThree from "../../asst/slider-img-7.png"
import imgFour from "../../asst/slider-img-9.png"
import imgFive from "../../asst/happy-customer-4.png"
import { MONTHS, MONTHSSMALL, getFirstNameAndLastInitial, getImageUrl } from "../../helper/index"
import { useSearchParams, Link } from "react-router-dom"
import ApiManager from "../../classes/ApiManager"
import {
  getAverageRating,
  getInitals,
  getPerformance,
  getThumbnailImageUrl,
  getTimeFromNow,
} from "../../helper"
import {
  QuoliLogo,
  SimpleStar,
  StarIcon,
} from "../PDPWidget/PDPConstants/PDPContants"
import { QuoliVerifyIcon } from "../../Svgs/Svg"
import PoweredByQuoli from "../v2/PoweredByQuoli"
import TextOnlySlider from "../ManCraft/ManCraftTextOnlySlider"
import ManCraftTextOnlySlider from "../ManCraft/ManCraftTextOnlySlider"
import RatingStars from "../PDPWidget/ChildComponents/RatingStars/RatingStars"

let apiManager = ApiManager.getInstance()

const Sliders = () => {
  const [searchParams] = useSearchParams()

  const [reviewStats, setReviewStats] = useState()
  const [reviewCount, setReviewsCount] = useState()

  const [reviews, setReviews] = useState({ docs: [] })
  const [totalDocs, setTotalDocs] = useState(0)
  let xToken = searchParams.get("store")
  let productId = searchParams.get("productId")
  let carouselSelected = searchParams.get("carouselSelected")
  let carouselTitle = searchParams.get("carouselTitle")

  let carouselSubtitle = searchParams.get("subHeading")

  apiManager?._setXToken(xToken)

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsPDPWidget", response.result)
        setBrandSettingsData(response.result)
        console.log(brandSettingsData, "")
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
    apiManager.get("getGeneralSettings").then((res) => {
      apiManager._setGeneralSettings(
        res?.result?.reviewersNameFormat?.displayName
      )
    })
  }, [])

  let totalreviews = searchParams.get("total-reviews")
  let nooflines = parseInt(searchParams.get("no-of-lines") ?? 50)
  console.log("nooflines", nooflines)
  let autoscroll = searchParams.get("auto_scroll") == "true"
  let autoscrolspeed = parseInt(searchParams.get("auto_scrol_speed")) * 1000
  let sliderfont = searchParams.get("slider_font")
  let starcolor = brandSettingsData?.generalAppearance?.starColor ?? "#ffc803"
  let backgroundcolor =
    brandSettingsData?.generalAppearance?.cardFillColor ?? "#ffffff"
  let cardbordercolor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"
  let cardshadowcolor = "0px 0px 17.8px 0px rgba(0,0,0,0.14)"
  let cardhovercolor =
    brandSettingsData?.generalAppearance?.cardFillColor ?? "#ffffff"
  let cardborderhovercolor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"
  let cardshadowhovercolor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"
  let textcolor = brandSettingsData?.generalAppearance?.reviewColor ?? "#000000"
  let textcolorHalf =
    `${brandSettingsData?.generalAppearance?.reviewColor}50` ?? "#000000"
  let chevroniconcolor = "#" + searchParams.get("chevron_icon_color")
  let verifiedbadgecolor =
    brandSettingsData?.generalAppearance?.verifiedColor ?? "#000000"
  let ctaFillColor =
    brandSettingsData?.generalAppearance?.ctaFillColor ?? "#ffffff"
  let cardFillColor =
    brandSettingsData?.generalAppearance?.cardFillColor ?? "#ffffff"

  console.log(verifiedbadgecolor, "verifiedbadgecolorverifiedbadgecolor")

  useEffect(() => {
    getReviews()
  }, [])

  var sendMaxHeightToParent = (docs) => {
    const maxHeight = document.body.scrollHeight
    console.log("carouselHeight", maxHeight)
    // window.parent.postMessage(
    //   { carouselHeight: maxHeight },
    //   "*"
    // )

    console.log(
      "NuggetsHeight === 0 && nuggets?.length > 0",
      docs?.length > 0,
      document.body.scrollHeight
    )
    if (maxHeight === 0 && docs?.length > 0) {
      maxHeight = 700
    }
    window.parent.postMessage({ carouselHeight: maxHeight }, "*")
  }

  const getReviews = () => {
    let body = {
      // productId,
      merchantId: xToken,
      isOnHomePage: true,
      limit: totalreviews === "all" ? 999 : parseInt(totalreviews),
    }
    if (
      carouselSelected !== "7" &&
      carouselSelected !== "6" &&
      carouselSelected !== "5" && carouselSelected !== "10"
    ) {
      body = {
        ...body,
        contentType: 1,
      }
    }
    apiManager.get("getReviews", body).then((res) => {
      console.log("res===>", res.result)
      console.log("body===>", body)
      setTotalDocs(res.result?.totalDocs ?? 0)
      setReviews(res.result)
      console.log("reviews===>", res.result?.totalDocs)
      setTimeout(() => {
        sendMaxHeightToParent(res?.result?.docs)
      }, 1000)
    })
    apiManager.get("getReviewStats", body).then((res) => {
      console.log("getReviewStats===>", res.result)
      setReviewStats(res.result)
    })
  }

  useEffect(() => {
    getReviewsCount()
  }, [])

  const getReviewsCount = () => {
    let body = {
      merchantId: xToken,
      isOnHomePage: true,
    }
    if (
      carouselSelected !== "7" &&
      carouselSelected !== "6" &&
      carouselSelected !== "5"
    ) {
      body = {
        ...body,
        // contentType: 2,
      }
    }
    apiManager.get("getReviewsCount", body).then((res) => {
      console.log("getReviewsCount===>", res.response)
      setReviewsCount(res.response)
    })
  }

  useEffect(() => {
    window.addEventListener("load", sendMaxHeightToParent)

    return () => {
      window.removeEventListener("load", sendMaxHeightToParent)
    }
  }, [])

  console.log(reviews, "===>reviews")

  // function formatDate(timestamp) {
  //   // Convert timestamp to milliseconds
  //   const date = new Date(timestamp)

  //   // Extract day, month, and year
  //   const day = date.getDate()
  //   const monthIndex = date.getMonth()
  //   const year = date.getFullYear()

  //   // Format the date
  //   const formattedDate = MONTHS[monthIndex] + " " + day + ", " + year

  //   return formattedDate
  // }

  function formatDate(timestamp) {
    // Convert timestamp to milliseconds
    const date = new Date(timestamp)

    // Current date
    const currentDate = new Date()

    console.log(currentDate, "currentDate")

    // Calculate the difference in milliseconds
    const differenceInMs = currentDate - date

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))

    console.log(differenceInDays, "this Is diff")

    if (differenceInDays === 0) {
      return "Today"
    } else if (differenceInDays === 1) {
      return "1 day ago"
    } else if (differenceInDays > 1 && differenceInDays <= 6) {
      return differenceInDays + " days ago"
    } else {
      // Extract day, month, and year
      const day = date.getDate()
      const monthIndex = date.getMonth()
      const year = date.getFullYear()

      // Format the date
      return (
        MONTHSSMALL[monthIndex] + " " + (day < 10 ? "0" + day : day) + ", " + year
      )
    }
  }

  console.log(getAverageRating(reviewStats, reviewCount), "numberrrating")

  // const starColor = "#ffc803"
  // const ratings = [
  //   { label: <div>All</div>, value: "All" },
  //   ...ratingValues.map((item) => ({
  //     label: Array(item)
  //       .fill(
  //         <SimpleStar
  //           color={starColor === "#null" ? "#EB2136" : starColor}
  //           key={item}
  //         />
  //       )
  //       .map((star, index) => <div key={index}>{star}</div>),
  //     value: item,
  //   })),
  // ]

  // const [count, setCount] = useState(1)

  // // Function to increment count until count + 2 equals 5
  // const incrementCountUntilFive = () => {
  //   while (count + 2 <= 5) {
  //     setCount((prevCount) => prevCount + 1)
  //   }
  // }

  const truncateName = (name, limit) => {
    if (name.length > limit) {
      return name.substring(0, limit) + "..."
    }
    return name
  }

  console.log(getAverageRating(reviewStats, reviewCount), "countRating")

  console.log(reviews?.docs, "ratingCheckInDocs")

  return (
    <div
      className="sliders"
      style={{
        "--card_hover_color": cardhovercolor,
        "--card_border_hover_color": cardborderhovercolor,
        "--card_shadow_hover_color": cardshadowhovercolor,
        "--chevron_icon_color": chevroniconcolor,
        "--family-font": xToken === "conatural.myshopify.com" ? "Poppins, sans-serif" : xToken === "one-degree-pk.myshopify.com" ? "Montserrat, sans-serif" : ""
        // background: "grey"
      }}
      onClick={() => window.parent.postMessage({ QuoliWidgetTrack: "carousel-clicked" }, "*")}
    >
      {carouselSelected === "1" ? (
        <div className="slider-wrapper-1">
          <div className="d-flex flex-column gap-1">
            <h2
              className={`carouselTitle ${xToken === "versusforher-mean3.myshopify.com" &&
                "versus-slider"
                }`}
              style={{
                fontFamily:
                  xToken === "ecs-website.myshopify.com" ||
                    xToken === "versusforher-mean3.myshopify.com"
                    ? "Poppins, sans-serif"
                    : "",
                fontSize:
                  xToken === "ecs-website.myshopify.com" ? "18px" : "",
                fontWeight:
                  xToken === "ecs-website.myshopify.com" ? "700" : "",
              }}
            >
              {xToken !== "versusforher-mean3.myshopify.com" &&
                carouselTitle}
              {xToken === "versusforher-mean3.myshopify.com" && (
                <span>{carouselTitle}</span>
              )}
            </h2>
            <p className="carousel-sub-heading">{carouselSubtitle}</p>
          </div>
          <>
            <Swiper
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-next"
              }}
              slidesPerView={5}
              spaceBetween={42}
              autoplay={
                autoscroll
                  ? {
                    delay: autoscrolspeed ?? 5000,
                  }
                  : false
              }
              modules={[Navigation, Autoplay]}
              initialSlide="3"
              centeredSlides={true}
              centeredSlidesBounds={true}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },

                450: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                550: {
                  slidesPerView: 2.6,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                768: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                  centeredSlides: false,
                },
                1000: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
              className="slider_1"
            >
              {reviews?.docs?.map((item, index) => (
                <SwiperSlide key={index} style={{ borderRadius: xToken === "versusforher-mean3.myshopify.com" ? "12px" : "" }}>
                  <div
                    className="slide-content c-pointer"
                    style={{
                      background: backgroundcolor,
                      border: `1 px solid ${cardbordercolor}`,
                      boxShadow: xToken === "versusforher-mean3.myshopify.com" ? "unset" : ""
                      // boxShadow: cardshadowcolor,
                    }}
                    onClick={() => {
                      setTimeout(
                        () =>
                          window.parent.postMessage(
                            { reviewId: item._id },
                            "*"
                          ),
                        200
                      )
                    }}
                  >
                    {xToken === "versusforher-mean3.myshopify.com" ? "" : (
                      <div className="top-section video-img">
                        {item.images?.length > 0 ? (
                          item.images[0].mediaType == 2 ? (
                            <img
                              src={getThumbnailImageUrl(item.images[0].mediaUrl)}
                              className="happy-customer-img"
                              alt="customer"
                            />
                          ) : (
                            <>
                              <video
                                src={getImageUrl(item.images[0].mediaUrl)}
                                className="happy-customer-img "
                                controls={false}
                              />
                              <button className="video-btn">
                                <svg width="43" height="45" viewBox="0 0 43 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="21.7495" cy="21.8343" r="20.8896" fill="white" fill-opacity="0.7" />
                                  <path d="M16.8391 32.413L24.5117 27.515V17.7189L16.8391 12.8209V32.413ZM24.5117 27.515L32.1844 22.6169L24.5117 17.7189V27.515Z" fill="#050505" />
                                </svg>

                              </button>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    )}

                    <div className="bottom-section ">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-1">
                          <span
                            className="customer-name"
                            style={{ color: textcolor }}
                          >
                            {truncateName(item.reviewerName, 10)}
                          </span>
                          {item.isAuthorized ? (
                            <div className="verified-customer">
                              <svg
                                width="12"
                                height="14"
                                viewBox="0 0 12 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                  fill={verifiedbadgecolor}
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <span
                          className="date-carousels-two"
                          style={{ color: textcolorHalf }}
                        >
                          {formatDate(item.updatedAt)}
                        </span>
                      </div>
                      <div className="happy-customer-stars">
                        {Array(item.reviewRating)
                          .fill()
                          .map((_, i) => (
                            <div className="star">
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 47 47"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_667_44648)">
                                  <path
                                    d="M22.3565 4.8564C22.8072 4.10748 23.8929 4.10748 24.3437 4.8564L30.3357 14.8132C30.4977 15.0822 30.7618 15.2741 31.0677 15.345L42.3888 17.967C43.2404 18.1642 43.5759 19.1968 43.0029 19.8569L35.3851 28.6325C35.1792 28.8697 35.0784 29.1802 35.1055 29.493L36.1103 41.0703C36.1858 41.9411 35.3074 42.5793 34.5026 42.2383L23.8024 37.7052C23.5133 37.5827 23.1868 37.5827 22.8977 37.7052L12.1975 42.2383C11.3927 42.5793 10.5143 41.9411 10.5899 41.0703L11.5946 29.493C11.6218 29.1802 11.5209 28.8697 11.315 28.6325L3.69722 19.8569C3.12423 19.1968 3.45975 18.1642 4.3113 17.967L15.6324 15.345C15.9383 15.2741 16.2025 15.0822 16.3644 14.8132L22.3565 4.8564Z"
                                    fill={starcolor}
                                    stroke={starcolor}
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_667_44648">
                                    <rect
                                      width="46.3861"
                                      height="46.3861"
                                      fill="white"
                                      transform="translate(0.158203)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          ))}
                        {Array(5 - (item?.reviewRating ?? 0))
                          .fill()
                          .map((_, i) => (
                            <div className="star">
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 47 47"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_667_44648)">
                                  <path
                                    d="M22.3565 4.8564C22.8072 4.10748 23.8929 4.10748 24.3437 4.8564L30.3357 14.8132C30.4977 15.0822 30.7618 15.2741 31.0677 15.345L42.3888 17.967C43.2404 18.1642 43.5759 19.1968 43.0029 19.8569L35.3851 28.6325C35.1792 28.8697 35.0784 29.1802 35.1055 29.493L36.1103 41.0703C36.1858 41.9411 35.3074 42.5793 34.5026 42.2383L23.8024 37.7052C23.5133 37.5827 23.1868 37.5827 22.8977 37.7052L12.1975 42.2383C11.3927 42.5793 10.5143 41.9411 10.5899 41.0703L11.5946 29.493C11.6218 29.1802 11.5209 28.8697 11.315 28.6325L3.69722 19.8569C3.12423 19.1968 3.45975 18.1642 4.3113 17.967L15.6324 15.345C15.9383 15.2741 16.2025 15.0822 16.3644 14.8132L22.3565 4.8564Z"
                                    fill="#D3D3D3"
                                    stroke="#D3D3D3"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_667_44648">
                                    <rect
                                      width="46.3861"
                                      height="46.3861"
                                      fill="white"
                                      transform="translate(0.158203)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          ))}
                        {/* <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.51141 0.635986L10.5567 6.10575L16.3908 6.36068L11.8207 9.9961L13.3811 15.6234L8.51141 12.4005L3.6417 15.6234L5.20208 9.9961L0.632052 6.36068L6.46613 6.10575L8.51141 0.635986Z"
                            fill="black"
                          />
                        </svg> */}
                      </div>

                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          gap: "25px",
                        }}
                      >
                        {item.reviewDetails && <p
                          className="title title-carousel-one"
                          style={{ color: textcolor }}
                        >
                          {item.reviewDetails?.length > 25
                            ? `${item.reviewDetails?.substring(
                              0,
                              25
                            )}...`
                            : item.reviewDetails}
                        </p>}
                        <p
                          className=" description-carousel-one"
                          style={{ color: textcolor }}
                        >
                          {item.reviewDescription?.length > nooflines
                            ? `${item.reviewDescription?.substring(
                              0,
                              nooflines
                            )}...`
                            : item.reviewDescription}
                        </p>
                      </div>
                      {/* {item.isAuthorized && (
                        <div className="verified-customer pt-4">
                          <svg
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                              fill={verifiedbadgecolor}
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                              fill="white"
                            />
                          </svg>
                          <span className="text" style={{ color: textcolor }}>
                            verified customer
                          </span>
                        </div>
                      )} */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </>
          <PoweredByQuoli />
        </div>
      ) : carouselSelected === "2" ? (
        <div className="slider-wrapper-2">
          <div className="d-flex flex-column gap-1">
            <h2 style={{ fontFamily: xToken === "beacn.myshopify.com" ? "Panton Caps" : "", fontSize: "32px" }}>{carouselTitle}</h2>
            <p className="carousel-sub-heading">{carouselSubtitle}</p>
          </div>
          <>
            <Swiper
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-next"
              }}
              slidesPerView={5}
              spaceBetween={42}
              autoplay={
                autoscroll
                  ? {
                    delay: autoscrolspeed ?? 5000,
                  }
                  : false
              }
              modules={[Navigation, Autoplay]}
              initialSlide="2"
              loop={true}
              breakpoints={{
                300: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                340: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                375: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                400: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                450: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                550: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                768: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                  centeredSlides: false,
                },
                1000: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
              className="slider_2"
              style={{
                padding: "25px 0px",
              }}
            >
              {reviews?.docs?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="slide-content c-pointer"
                    // style={{ boxShadow: cardshadowcolor }}
                    style={{ paddingTop: "0px" }}
                    onClick={() => {
                      setTimeout(
                        () =>
                          window.parent.postMessage(
                            { reviewId: item._id },
                            "*"
                          ),
                        200
                      )
                    }}
                  >
                    <div
                      className="top-section"
                      style={{
                        background: backgroundcolor,
                        border: "1px solid",
                        borderBottom: "0px",
                        borderColor: cardbordercolor,
                        borderBottomColor: "transparent",
                        height: "248px",
                      }}
                    >
                      <div
                        className="happy-customer-stars"
                        style={{
                          boxShadow: "0px -4px 10.8px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        {Array(item.reviewRating)
                          .fill()
                          .map((_, i) => (
                            <div className="star">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 47 47"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_667_44648)">
                                  <path
                                    d="M22.3565 4.8564C22.8072 4.10748 23.8929 4.10748 24.3437 4.8564L30.3357 14.8132C30.4977 15.0822 30.7618 15.2741 31.0677 15.345L42.3888 17.967C43.2404 18.1642 43.5759 19.1968 43.0029 19.8569L35.3851 28.6325C35.1792 28.8697 35.0784 29.1802 35.1055 29.493L36.1103 41.0703C36.1858 41.9411 35.3074 42.5793 34.5026 42.2383L23.8024 37.7052C23.5133 37.5827 23.1868 37.5827 22.8977 37.7052L12.1975 42.2383C11.3927 42.5793 10.5143 41.9411 10.5899 41.0703L11.5946 29.493C11.6218 29.1802 11.5209 28.8697 11.315 28.6325L3.69722 19.8569C3.12423 19.1968 3.45975 18.1642 4.3113 17.967L15.6324 15.345C15.9383 15.2741 16.2025 15.0822 16.3644 14.8132L22.3565 4.8564Z"
                                    fill={starcolor}
                                    stroke={starcolor}
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_667_44648">
                                    <rect
                                      width="46.3861"
                                      height="46.3861"
                                      fill="white"
                                      transform="translate(0.158203)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          ))}
                        {Array(5 - (item?.reviewRating ?? 0))
                          .fill()
                          .map((_, i) => (
                            <div className="star">
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 47 47"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_667_44648)">
                                  <path
                                    d="M22.3565 4.8564C22.8072 4.10748 23.8929 4.10748 24.3437 4.8564L30.3357 14.8132C30.4977 15.0822 30.7618 15.2741 31.0677 15.345L42.3888 17.967C43.2404 18.1642 43.5759 19.1968 43.0029 19.8569L35.3851 28.6325C35.1792 28.8697 35.0784 29.1802 35.1055 29.493L36.1103 41.0703C36.1858 41.9411 35.3074 42.5793 34.5026 42.2383L23.8024 37.7052C23.5133 37.5827 23.1868 37.5827 22.8977 37.7052L12.1975 42.2383C11.3927 42.5793 10.5143 41.9411 10.5899 41.0703L11.5946 29.493C11.6218 29.1802 11.5209 28.8697 11.315 28.6325L3.69722 19.8569C3.12423 19.1968 3.45975 18.1642 4.3113 17.967L15.6324 15.345C15.9383 15.2741 16.2025 15.0822 16.3644 14.8132L22.3565 4.8564Z"
                                    fill="#D3D3D3"
                                    stroke="#D3D3D3"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_667_44648">
                                    <rect
                                      width="46.3861"
                                      height="46.3861"
                                      fill="white"
                                      transform="translate(0.158203)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          ))}
                      </div>
                      {item.images?.length > 0 ? (
                        item.images[0].mediaType == 2 ? (
                          <img
                            src={getThumbnailImageUrl(item.images[0].mediaUrl)}
                            className="happy-customer-img"
                          />
                        ) : (
                          <video
                            src={getImageUrl(item.images[0].mediaUrl)}
                            className="happy-customer-img"
                            controls={false}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className="bottom-section"
                      style={{
                        background: backgroundcolor,
                        border: "1px solid",
                        borderTop: "0px",
                        borderColor: cardbordercolor,
                        borderTopColor: "transparent",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="date-carousels-two"
                        style={{ color: textcolorHalf }}
                      >
                        {formatDate(item?.createdAt)}
                      </span>
                      <p
                        className="title-carousel-two"
                        style={{
                          color: textcolor,

                          padding: "8px 0px 5px 0px",
                        }}
                      >
                        {item.reviewDetails?.length > 25
                          ? `${item.reviewDetails?.substring(0, 25)}...`
                          : item.reviewDetails}
                      </p>
                      <p
                        className="description carousels-2-description"
                        style={{ color: textcolor }}
                      >
                        {item.reviewDescription?.length > nooflines
                          ? `${item.reviewDescription?.substring(
                            0,
                            nooflines
                          )}...`
                          : item.reviewDescription}
                      </p>
                      <div className="customer-name-wrapper pt-3 d-flex gap-1">
                        <h4
                          className="customer-name"
                          style={{ color: textcolor }}
                        >
                          {item.reviewerName}
                        </h4>
                        {/* <span
                          className="customer-avatar"
                          style={{
                            background: backgroundcolor,
                            color: textcolor,
                          }}
                        >
                          {getInitals(item.reviewerName ?? "")}
                        </span> */}
                        {item.isAuthorized ? (
                          <div className="verified-customer d-flex gap-1 align-items-center">
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                fill={verifiedbadgecolor}
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                fill="white"
                              />
                            </svg>
                            {/* <span className="text" style={{ color: textcolor }}>
                             Shop
                           </span> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </>
          <PoweredByQuoli />
        </div>
      ) : carouselSelected === "3" ? (
        <div className="slider-wrapper-3">
          <div className="d-flex flex-column gap-1">
            <h2>{carouselTitle}</h2>
            <p className="carousel-sub-heading">{carouselSubtitle}</p>
          </div>
          <>
            <Swiper
              navigation
              slidesPerView={5}
              spaceBetween={42}
              // centeredSlides={true}
              autoplay={
                autoscroll
                  ? {
                    delay: autoscrolspeed ?? 5000,
                  }
                  : false
              }
              modules={[Navigation, Autoplay]}
              initialSlide={0}
              loop={true}
              breakpoints={{
                300: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                340: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                375: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                400: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                450: {
                  slidesPerView: 1,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                550: {
                  slidesPerView: 2.2,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                650: {
                  slidesPerView: 2.5,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                768: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                  centeredSlides: false,
                },
                1000: {
                  slidesPerView: 5,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                1200: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1920: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
              className="slider_3"
            >
              {reviews?.docs?.map((item, index) => (
                <>
                  {item.images?.length > 0 ? (
                    item.images[0].mediaType == 1 && (
                      <SwiperSlide key={index}>
                        <div
                          className="slide-content c-pointer"
                          onClick={() => {
                            setTimeout(
                              () =>
                                window.parent.postMessage(
                                  { reviewId: item._id },
                                  "*"
                                ),
                              200
                            )
                          }}
                        >
                          <video
                            src={getImageUrl(item.images[0].mediaUrl) + '#t=0.5'}
                            className="happy-customer-img"
                            controls={false}
                          />

                          <span className="happy-custoom-svg">
                            <svg
                              width="26"
                              height="25"
                              viewBox="0 0 26 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12.5859"
                                cy="12.5"
                                r="12.5"
                                fill="white"
                                fill-opacity="0.7"
                              />
                              <path
                                d="M9.64648 18.1505L14.2377 15.3252V9.67447L9.64648 6.84912V18.1505ZM14.2377 15.3252L18.8289 12.4998L14.2377 9.67447V15.3252Z"
                                fill={"#050505"}
                              />
                            </svg>
                          </span>
                          <div className="lower-absolute-section">
                            <div className="happy-customer-stars">
                              {Array(item.reviewRating)
                                .fill()
                                .map((_, i) => (
                                  <div className="star">
                                    <svg
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.51141 0.635986L10.5567 6.10575L16.3908 6.36068L11.8207 9.9961L13.3811 15.6234L8.51141 12.4005L3.6417 15.6234L5.20208 9.9961L0.632052 6.36068L6.46613 6.10575L8.51141 0.635986Z"
                                        fill={"white"}
                                        stroke={"white"}
                                      />
                                    </svg>
                                  </div>
                                ))}
                              {Array(5 - (item?.reviewRating ?? 0))
                                .fill()
                                .map((_, i) => (
                                  <div className="star">
                                    <svg
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.51141 0.635986L10.5567 6.10575L16.3908 6.36068L11.8207 9.9961L13.3811 15.6234L8.51141 12.4005L3.6417 15.6234L5.20208 9.9961L0.632052 6.36068L6.46613 6.10575L8.51141 0.635986Z"
                                        stroke={"white"}
                                        fill="transparent"
                                      />
                                    </svg>
                                  </div>
                                ))}
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              <h4
                                className="customer-name"
                                style={{ color: "white" }}
                              >
                                {item.reviewerName}
                              </h4>
                              {item.isAuthorized ? (
                                <div className="verified-customer">
                                  <svg
                                    width="12"
                                    height="14"
                                    viewBox="0 0 12 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                      fill={"white"}
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </Swiper>
          </>
          <PoweredByQuoli />
        </div>
      ) : carouselSelected === "4" ? (
        <div className="slider-wrapper-4">
          <div className="d-flex flex-column gap-1">
            <h2>{carouselTitle}</h2>
            <p className="carousel-sub-heading">{carouselSubtitle}</p>
          </div>
          <>
            <Swiper
              navigation={true}
              slidesPerView={5}
              spaceBetween={42}
              centeredSlides={true}
              autoplay={
                autoscroll
                  ? {
                    delay: autoscrolspeed ?? 5000,
                  }
                  : false
              }
              modules={[Navigation, Autoplay]}
              initialSlide="4"
              loop={true}
              breakpoints={{
                300: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                340: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                375: {
                  slidesPerView: 1.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                400: {
                  slidesPerView: 1.75,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                450: {
                  slidesPerView: 1.75,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                550: {
                  slidesPerView: 2.2,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                650: {
                  slidesPerView: 2.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },

                768: {
                  slidesPerView: 3.25,
                  spaceBetween: 24,
                  centeredSlides: true,
                },
                1000: {
                  slidesPerView: 4.5,
                  spaceBetween: 24,
                  centeredSlides: true,
                },

                1200: {
                  slidesPerView: 4.4,
                  spaceBetween: 30,
                  centeredSlides: true,
                },
                1440: {
                  slidesPerView: 4.5,
                  spaceBetween: 30,
                  centeredSlides: true,
                },
                1920: {
                  slidesPerView: 4.5,
                  spaceBetween: 40,
                  centeredSlides: true,
                },
              }}
              className="slider_4"
            >
              {reviews?.docs?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="slide-content c-pointer"
                    onClick={() => {
                      setTimeout(
                        () =>
                          window.parent.postMessage(
                            { reviewId: item._id },
                            "*"
                          ),
                        200
                      )
                    }}
                  >
                    <div className="top-section">
                      {item.images?.length > 0 ? (
                        item.images[0].mediaType == 2 ? (
                          <img
                            src={getThumbnailImageUrl(item.images[0].mediaUrl)}
                            className="happy-customer-img"
                          />
                        ) : (
                          <video
                            src={getImageUrl(item.images[0].mediaUrl)}
                            className="happy-customer-img"
                            controls={false}
                          />
                        )
                      ) : (
                        <></>
                      )}
                      <div className="happy-customer-stars">
                        {Array(item.reviewRating)
                          .fill()
                          .map((_, i) => (
                            <div className="star">
                              <svg
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_18_35902)">
                                  <path
                                    d="M10.3491 3.19994C10.5584 2.85226 11.0624 2.85226 11.2717 3.19994L14.0534 7.82225C14.1286 7.94716 14.2512 8.03624 14.3932 8.06913L19.6489 9.28636C20.0442 9.37791 20.2 9.8573 19.934 10.1637L16.3975 14.2377C16.3019 14.3478 16.2551 14.4919 16.2677 14.6372L16.7342 20.0118C16.7692 20.416 16.3615 20.7123 15.9878 20.554L11.0204 18.4496C10.8862 18.3927 10.7346 18.3927 10.6004 18.4496L5.63298 20.554C5.25935 20.7123 4.85155 20.416 4.88664 20.0118L5.35308 14.6372C5.36569 14.4919 5.31886 14.3478 5.22329 14.2377L1.68682 10.1637C1.42081 9.8573 1.57658 9.37791 1.9719 9.28636L7.22759 8.06913C7.36961 8.03624 7.49222 7.94716 7.56739 7.82225L10.3491 3.19994Z"
                                    fill={starcolor}
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_18_35902">
                                    <rect
                                      width="21.5342"
                                      height="21.5342"
                                      fill="white"
                                      transform="translate(0.0421143 0.946167)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          ))}
                        {Array(5 - (item?.reviewRating ?? 0))
                          .fill()
                          .map((_, i) => (
                            <div className="star">
                              <svg
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_18_35902)">
                                  <path
                                    d="M10.3491 3.19994C10.5584 2.85226 11.0624 2.85226 11.2717 3.19994L14.0534 7.82225C14.1286 7.94716 14.2512 8.03624 14.3932 8.06913L19.6489 9.28636C20.0442 9.37791 20.2 9.8573 19.934 10.1637L16.3975 14.2377C16.3019 14.3478 16.2551 14.4919 16.2677 14.6372L16.7342 20.0118C16.7692 20.416 16.3615 20.7123 15.9878 20.554L11.0204 18.4496C10.8862 18.3927 10.7346 18.3927 10.6004 18.4496L5.63298 20.554C5.25935 20.7123 4.85155 20.416 4.88664 20.0118L5.35308 14.6372C5.36569 14.4919 5.31886 14.3478 5.22329 14.2377L1.68682 10.1637C1.42081 9.8573 1.57658 9.37791 1.9719 9.28636L7.22759 8.06913C7.36961 8.03624 7.49222 7.94716 7.56739 7.82225L10.3491 3.19994Z"
                                    fill="#D3D3D3"
                                    stroke="#D3D3D3"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_18_35902">
                                    <rect
                                      width="21.5342"
                                      height="21.5342"
                                      fill="white"
                                      transform="translate(0.0421143 0.946167)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div
                      className="bottom-section"
                      style={{
                        background: backgroundcolor,
                        border: "1px solid",
                        borderColor: cardbordercolor,
                      }}
                    >
                      <div className="d-flex align-items-center gap-1">
                        <span
                          className="customer-carousel-four"
                          style={{ color: textcolor }}
                        >
                          {item.reviewDetails?.length > 25
                            ? `${item.reviewDetails?.substring(0, 25)}...`
                            : item.reviewerName}
                        </span>
                        {item.isAuthorized ? (
                          <svg
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                              fill={verifiedbadgecolor}
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </div>

                      <p
                        className="description carousels-2-description"
                        style={{
                          color: textcolor,
                          fontSize: "16px !important",
                        }}
                      >
                        {item.reviewDescription?.length > nooflines
                          ? `${item.reviewDescription?.substring(
                            0,
                            nooflines
                          )}...`
                          : item.reviewDescription}
                      </p>

                      <div className="verified-customer">
                        <span
                          className="date-carousels-two"
                          // style={{ color: textcolor }}
                          style={{ color: textcolorHalf }}
                        >
                          {formatDate(item.updatedAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
          <PoweredByQuoli />
        </div>
      ) : carouselSelected === "5" ? (
        <div className="slider-wrapper-6">
          <div className="d-flex flex-column gap-1">
            <h2 className="carouselTitle" style={{
              fontFamily:
                xToken === "lm-soelle-2-0.myshopify.com" ? "Nanum Myeongjo, sans-serif"
                  : "",
              fontSize:
                xToken === "lm-soelle-2-0.myshopify.com" ? "32px" : "",
              fontWeight:
                xToken === "lm-soelle-2-0.myshopify.com" ? "700" : "",
              textTransform:
                xToken === "lm-soelle-2-0.myshopify.com" ? "uppercase" : "",
              color:
                xToken === "lm-soelle-2-0.myshopify.com" ? "#2F4849" : "",
            }}>{carouselTitle}</h2>
            <p className="carousel-sub-heading">{carouselSubtitle}</p>
          </div>

          <div class="slider-content">
            <div
              class="total-reviews"
              style={{
                background: backgroundcolor,
                border: "1px solid",
                borderColor: cardbordercolor,
              }}
            >
              <span class="status" style={{ color: textcolor }}>
                {getPerformance(getAverageRating(reviewStats, reviewCount))}
              </span>
              <div class="customer-reviews">
                <div class="happy-customer-stars">
                  {Array(
                    parseInt(getAverageRating(reviewStats, reviewCount) ?? 0) ??
                    0
                  )
                    .fill()
                    .map((_, i) => (
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 47 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_667_44644)">
                          <path
                            d="M22.5879 4.8564C23.0386 4.10748 24.1244 4.10748 24.5751 4.8564L30.5672 14.8132C30.7291 15.0822 30.9932 15.2741 31.2991 15.345L42.6203 17.967C43.4718 18.1642 43.8073 19.1968 43.2343 19.8569L35.6165 28.6325C35.4107 28.8697 35.3098 29.1802 35.3369 29.493L36.3417 41.0703C36.4173 41.9411 35.5389 42.5793 34.734 42.2383L24.0339 37.7052C23.7447 37.5827 23.4183 37.5827 23.1291 37.7052L12.429 42.2383C11.6241 42.5793 10.7457 41.9411 10.8213 41.0703L11.8261 29.493C11.8532 29.1802 11.7523 28.8697 11.5465 28.6325L3.92867 19.8569C3.35567 19.1968 3.6912 18.1642 4.54275 17.967L15.8639 15.345C16.1698 15.2741 16.4339 15.0822 16.5958 14.8132L22.5879 4.8564Z"
                            fill={starcolor}
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_667_44644">
                            <rect
                              width="46.3861"
                              height="46.3861"
                              fill={starcolor}
                              transform="translate(0.385986)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    ))}
                  {getAverageRating(reviewStats, reviewCount) -
                    Math.floor(getAverageRating(reviewStats, reviewCount)) >
                    0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_18_35991)">
                          <path
                            d="M12.487 2.76354C12.7299 2.35991 13.3151 2.35991 13.558 2.76354L16.7874 8.1298C16.8747 8.27481 17.017 8.37823 17.1819 8.41641L23.2835 9.82955C23.7424 9.93584 23.9233 10.4924 23.6144 10.8481L19.5088 15.5778C19.3978 15.7056 19.3435 15.8729 19.3581 16.0416L19.8996 22.2812C19.9404 22.7505 19.4669 23.0945 19.0332 22.9107L13.2663 20.4675C13.1104 20.4015 12.9345 20.4015 12.7787 20.4675L7.01176 22.9107C6.57798 23.0945 6.10456 22.7505 6.14529 22.2812L6.68681 16.0416C6.70144 15.8729 6.64707 15.7056 6.53613 15.5778L2.43047 10.8481C2.12165 10.4924 2.30249 9.93584 2.76143 9.82955L8.86301 8.41641C9.02788 8.37823 9.17023 8.27481 9.25749 8.1298L12.487 2.76354Z"
                            fill="#D3D3D3"
                          />
                          <path
                            d="M12.5119 2.7222C12.6556 2.48339 13.0225 2.58528 13.0225 2.864L13.0225 8.35667V11.1871V13.8833V17.8051L13.0225 19.9503C13.0225 20.2012 12.8724 20.4278 12.6413 20.5257L7.01183 22.9107C6.57806 23.0945 6.10464 22.7505 6.14537 22.2812L6.68689 16.0416C6.70152 15.8729 6.64715 15.7056 6.53621 15.5778L2.43055 10.8481C2.12173 10.4924 2.30256 9.93584 2.76151 9.82955L8.86308 8.41641C9.02796 8.37823 9.1703 8.27481 9.25757 8.1298L12.5119 2.7222Z"
                            fill={starcolor}
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_18_35991">
                            <rect
                              width="25"
                              height="25"
                              fill="white"
                              transform="translate(0.522949 0.145996)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}

                  {Array(
                    5 -
                    Math.ceil(
                      getAverageRating(reviewStats, reviewCount) ?? 0
                    ) ?? 0
                  )
                    .fill()
                    .map((_, i) => (
                      <div className="star">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 47 47"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_667_44648)">
                            <path
                              d="M22.3565 4.8564C22.8072 4.10748 23.8929 4.10748 24.3437 4.8564L30.3357 14.8132C30.4977 15.0822 30.7618 15.2741 31.0677 15.345L42.3888 17.967C43.2404 18.1642 43.5759 19.1968 43.0029 19.8569L35.3851 28.6325C35.1792 28.8697 35.0784 29.1802 35.1055 29.493L36.1103 41.0703C36.1858 41.9411 35.3074 42.5793 34.5026 42.2383L23.8024 37.7052C23.5133 37.5827 23.1868 37.5827 22.8977 37.7052L12.1975 42.2383C11.3927 42.5793 10.5143 41.9411 10.5899 41.0703L11.5946 29.493C11.6218 29.1802 11.5209 28.8697 11.315 28.6325L3.69722 19.8569C3.12423 19.1968 3.45975 18.1642 4.3113 17.967L15.6324 15.345C15.9383 15.2741 16.2025 15.0822 16.3644 14.8132L22.3565 4.8564Z"
                              fill="#D3D3D3"
                              stroke="#D3D3D3"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_667_44648">
                              <rect
                                width="46.3861"
                                height="46.3861"
                                fill="white"
                                transform="translate(0.158203)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    ))}
                </div>
              </div>
              <div class="rating">
                <span class="rating-no" style={{ color: textcolor }}>
                  {getAverageRating(reviewStats, reviewCount)}/5 Rating
                </span>
              </div>
              <div class="reviews" style={{ color: textcolor }}>
                Based on {reviewCount} reviews
              </div>
              <span className="powererd-by-quoli" style={{ color: textcolor }}>
                {/* Powered by&nbsp;
                <a target="_blank" href="https://quoli.io">
                  <QuoliVerifyIcon />
                </a> */}
                Verified by Quoli
              </span>
            </div>
            <Swiper
              navigation={true}
              slidesPerView={1}
              spaceBetween={42}
              // centeredSlides={true}
              loop={true}
              autoplay={
                autoscroll
                  ? {
                    delay: autoscrolspeed ?? 5000,
                  }
                  : false
              }
              modules={[Navigation, Autoplay]}
              className="slider_6"
            >
              {reviews?.docs?.map((item, index) => (
                <div>
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        class="slide-content c-pointer slider-content-6 carousel-6-height"
                        style={{
                          background: backgroundcolor,
                          border: "1px solid",
                          borderColor: cardbordercolor,
                          minHeight:
                            item?.images?.length > 0 ? "auto" : "225px",
                          border: "1px solid green",
                        }}
                        onClick={() => {
                          setTimeout(
                            () =>
                              window.parent.postMessage(
                                { reviewId: item._id },
                                "*"
                              ),
                            200
                          )
                        }}
                      >
                        <div class="top-section">
                          <div className="d-flex justify-content-between">
                            <div class="verified-customer">
                              <span
                                class="text text-carousel-five"
                                style={{ color: textcolor }}
                              >
                                {" "}
                                {item.reviewerName}
                              </span>
                              {item?.isAuthorized ? (
                                <svg
                                  width="12"
                                  height="14"
                                  viewBox="0 0 12 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                    fill={verifiedbadgecolor}
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                ""
                              )}
                            </div>

                            <div>
                              <span
                                class="days-ago"
                                style={{ color: textcolorHalf }}
                              >
                                {formatDate(item.createdAt)}
                              </span>
                            </div>
                          </div>
                          <div class="customer-reviews">
                            <div className="happy-customer-stars">
                              {Array(item?.reviewRating)
                                .fill()
                                .map((_, i) => {
                                  console.log("their is fine?")
                                  return (
                                    <div className="star">
                                      <svg
                                        width="22"
                                        height="23"
                                        viewBox="0 0 22 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_18_35902)">
                                          <path
                                            d="M10.3491 3.19994C10.5584 2.85226 11.0624 2.85226 11.2717 3.19994L14.0534 7.82225C14.1286 7.94716 14.2512 8.03624 14.3932 8.06913L19.6489 9.28636C20.0442 9.37791 20.2 9.8573 19.934 10.1637L16.3975 14.2377C16.3019 14.3478 16.2551 14.4919 16.2677 14.6372L16.7342 20.0118C16.7692 20.416 16.3615 20.7123 15.9878 20.554L11.0204 18.4496C10.8862 18.3927 10.7346 18.3927 10.6004 18.4496L5.63298 20.554C5.25935 20.7123 4.85155 20.416 4.88664 20.0118L5.35308 14.6372C5.36569 14.4919 5.31886 14.3478 5.22329 14.2377L1.68682 10.1637C1.42081 9.8573 1.57658 9.37791 1.9719 9.28636L7.22759 8.06913C7.36961 8.03624 7.49222 7.94716 7.56739 7.82225L10.3491 3.19994Z"
                                            fill={starcolor}
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_18_35902">
                                            <rect
                                              width="21.5342"
                                              height="21.5342"
                                              fill="white"
                                              transform="translate(0.0421143 0.946167)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                  )
                                })}
                              {Array(5 - (item?.reviewRating ?? 0))
                                .fill()
                                .map((_, i) => {
                                  console.log(item?.reviewRating, "rating")
                                  return (
                                    <div className="star">
                                      <svg
                                        width="22"
                                        height="23"
                                        viewBox="0 0 22 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_18_35902)">
                                          <path
                                            d="M10.3491 3.19994C10.5584 2.85226 11.0624 2.85226 11.2717 3.19994L14.0534 7.82225C14.1286 7.94716 14.2512 8.03624 14.3932 8.06913L19.6489 9.28636C20.0442 9.37791 20.2 9.8573 19.934 10.1637L16.3975 14.2377C16.3019 14.3478 16.2551 14.4919 16.2677 14.6372L16.7342 20.0118C16.7692 20.416 16.3615 20.7123 15.9878 20.554L11.0204 18.4496C10.8862 18.3927 10.7346 18.3927 10.6004 18.4496L5.63298 20.554C5.25935 20.7123 4.85155 20.416 4.88664 20.0118L5.35308 14.6372C5.36569 14.4919 5.31886 14.3478 5.22329 14.2377L1.68682 10.1637C1.42081 9.8573 1.57658 9.37791 1.9719 9.28636L7.22759 8.06913C7.36961 8.03624 7.49222 7.94716 7.56739 7.82225L10.3491 3.19994Z"
                                            fill="#D3D3D3"
                                            stroke="#D3D3D3"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_18_35902">
                                            <rect
                                              width="21.5342"
                                              height="21.5342"
                                              fill="#D3D3D3"
                                              transform="translate(0.0421143 0.946167)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                  )
                                })}
                            </div>
                          </div>
                          <p
                            class="title text-start"
                            style={{ color: textcolor }}
                          >
                            {item?.reviewDetails?.length > 25
                              ? `${item?.reviewDetails?.substring(0, 25)}...`
                              : item?.reviewDetails}
                          </p>
                          <p class="description" style={{ color: textcolor }}>
                            {item?.reviewDescription?.length > nooflines
                              ? `${item?.reviewDescription?.substring(
                                0,
                                nooflines
                              )}...`
                              : item?.reviewDescription}
                          </p>
                          {/* <span class="days-ago">
                        {getTimeFromNow(item.createdAt)}
                      </span> */}
                        </div>

                        {item?.images?.length > 0 ? (
                          <div class="bottom-section">
                            {item.images?.length > 0 ? (
                              item.images[0].mediaType == 2 ? (
                                <img
                                  src={getThumbnailImageUrl(
                                    item.images[0].mediaUrl
                                  )}
                                  className="happy-customer-img"
                                />
                              ) : (
                                <video
                                  src={getImageUrl(item.images[0].mediaUrl)}
                                  className="happy-customer-img"
                                  controls={false}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      ) : carouselSelected === "6" ? (
        <div className="slider-wrapper-7">
          <div className="d-flex flex-column gap-1">
            <h2 className="carouselTitle">{carouselTitle}</h2>
            <p className="carousel-sub-heading">{carouselSubtitle}</p>
          </div>
          <div class="slider-content">
            <div
              class="total-reviews"
              style={{
                background: backgroundcolor,
                border: "1px solid",
                borderColor: cardbordercolor,
              }}
            >
              <span class="status" style={{ color: textcolor }}>
                {getPerformance(getAverageRating(reviewStats, reviewCount))}
              </span>
              <div class="rating">
                <span class="rating-no" style={{ color: textcolor }}>
                  {getAverageRating(reviewStats, reviewCount)}
                </span>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.94426 0.473426C8.13206 0.161353 8.5845 0.161354 8.77231 0.473426L11.2692 4.62235C11.3366 4.73446 11.4467 4.81442 11.5742 4.84394L16.2916 5.9365C16.6464 6.01868 16.7862 6.44898 16.5475 6.72403L13.3732 10.3808C13.2874 10.4796 13.2454 10.609 13.2567 10.7393L13.6754 15.5635C13.7069 15.9263 13.3408 16.1923 13.0055 16.0502L8.54678 14.1613C8.4263 14.1102 8.29027 14.1102 8.16978 14.1613L3.71111 16.0502C3.37574 16.1923 3.00971 15.9263 3.0412 15.5635L3.45988 10.7393C3.47119 10.609 3.42915 10.4796 3.34338 10.3808L0.169091 6.72403C-0.0696714 6.44898 0.0701392 6.01868 0.424973 5.9365L5.1424 4.84394C5.26988 4.81442 5.37993 4.73446 5.4474 4.62235L7.94426 0.473426Z"
                    fill={starcolor}
                  />
                </svg>
              </div>
              <div class="reviews" style={{ color: textcolor }}>
                {reviewCount} Reviews
              </div>

              {/* <PoweredByQuoli /> */}

              <span className="powererd-by-quoli" style={{ color: textcolor }}>
                {/* Powered by&nbsp;
                <a target="_blank" href="https://quoli.io">
                  <QuoliVerifyIcon />
                </a> */}
                Verified by Quoli
              </span>
            </div>
            <div className="carousel-6-swiper-div">
              <Swiper
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-next"
                }}
                slidesPerView={3}
                spaceBetween={25}
                // centeredSlides={true}
                autoplay={
                  autoscroll
                    ? {
                      delay: autoscrolspeed ?? 5000,
                    }
                    : false
                }
                modules={[Navigation, Autoplay]}
                initialSlide={1}
                loop={true}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },
                  340: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },
                  375: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },
                  400: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },
                  450: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },
                  550: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },
                  550: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    centeredSlides: true,
                  },

                  768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                    centeredSlides: false,
                  },
                  1000: {
                    slidesPerView: 2,
                    spaceBetween: 24,

                  },

                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1440: {
                    slidesPerView: 3,
                    // spaceBetween: 30,
                  },
                  1920: {
                    slidesPerView: 3,
                    // spaceBetween: 40,
                  },
                }}
                className="slider_7"
              >
                {reviews.docs?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      class="slide-content c-pointer"
                      style={{
                        background: backgroundcolor,
                        border: "1px solid",
                        borderColor: cardbordercolor,
                        padding: "25px 15px",
                      }}
                      onClick={() => {
                        setTimeout(
                          () =>
                            window.parent.postMessage(
                              { reviewId: item._id },
                              "*"
                            ),
                          200
                        )
                      }}
                    >
                      <div class="top-section">
                        <div class="customer-reviews">
                          <div class="happy-customer-stars">
                            {Array(item.reviewRating)
                              .fill()
                              .map((_, i) => (
                                <div className="star">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.25779 0.308238C8.4456 -0.00383434 8.89804 -0.00383398 9.08584 0.308238L11.5827 4.45716C11.6502 4.56927 11.7602 4.64923 11.8877 4.67875L16.6051 5.77132C16.96 5.8535 17.0998 6.28379 16.861 6.55884L13.6867 10.2156C13.6009 10.3144 13.5589 10.4438 13.5702 10.5741L13.9889 15.3983C14.0204 15.7612 13.6544 16.0271 13.319 15.885L8.86032 13.9961C8.73983 13.945 8.6038 13.945 8.48332 13.9961L4.02465 15.885C3.68927 16.0271 3.32325 15.7611 3.35474 15.3983L3.77341 10.5741C3.78473 10.4438 3.74269 10.3144 3.65691 10.2156L0.482629 6.55884C0.243866 6.28379 0.383677 5.8535 0.738511 5.77132L5.45594 4.67875C5.58341 4.64923 5.69347 4.56927 5.76094 4.45716L8.25779 0.308238Z"
                                      fill={starcolor}
                                      stroke={starcolor}
                                    />
                                  </svg>
                                </div>
                              ))}
                            {Array(5 - (item?.reviewRating ?? 0))
                              .fill()
                              .map((_, i) => (
                                <div className="star">
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.25779 0.308238C8.4456 -0.00383434 8.89804 -0.00383398 9.08584 0.308238L11.5827 4.45716C11.6502 4.56927 11.7602 4.64923 11.8877 4.67875L16.6051 5.77132C16.96 5.8535 17.0998 6.28379 16.861 6.55884L13.6867 10.2156C13.6009 10.3144 13.5589 10.4438 13.5702 10.5741L13.9889 15.3983C14.0204 15.7612 13.6544 16.0271 13.319 15.885L8.86032 13.9961C8.73983 13.945 8.6038 13.945 8.48332 13.9961L4.02465 15.885C3.68927 16.0271 3.32325 15.7611 3.35474 15.3983L3.77341 10.5741C3.78473 10.4438 3.74269 10.3144 3.65691 10.2156L0.482629 6.55884C0.243866 6.28379 0.383677 5.8535 0.738511 5.77132L5.45594 4.67875C5.58341 4.64923 5.69347 4.56927 5.76094 4.45716L8.25779 0.308238Z"
                                      fill="#D3D3D3"
                                      stroke="#D3D3D3"
                                    />
                                  </svg>
                                </div>
                              ))}
                          </div>
                          <span
                            style={{ color: textcolorHalf }}
                            class="days-ago"
                          >
                            {formatDate(item.createdAt)}
                          </span>
                        </div>
                        <p
                          class="title text-start"
                          style={{ color: textcolor }}
                        >
                          {item.reviewDetails?.length > 25
                            ? `${item.reviewDetails?.substring(0, 25)}...`
                            : item.reviewDetails}
                        </p>
                        <p class="description" style={{ color: textcolor }}>
                          {item.reviewDescription?.length > nooflines
                            ? `${item.reviewDescription?.substring(
                              0,
                              nooflines
                            )}...`
                            : item.reviewDescription}
                        </p>
                      </div>

                      <div class="bottom-section d-flex align-items-center gap-1 pt-2">
                        <div>
                          <h4
                            class="customer-name"
                            style={{ color: textcolor }}
                          >
                            {item.reviewerName}
                          </h4>
                        </div>
                        {item.isAuthorized ? (
                          <div className="verified-customer">
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                fill={verifiedbadgecolor}
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                fill="white"
                              />
                            </svg>
                            {/* <span className="text" style={{ color: textcolor }}>
                            verified customer
                          </span> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      ) : //this below carousel no is 7
        carouselSelected === "7" ? (
          <>
            {xToken === "7ddac4-3.myshopify.com" ? (
              <ManCraftTextOnlySlider
                carouselTitle={carouselTitle}
                // carouselSubtitle={carouselSubtitle}
                autoscroll={autoscroll}
                autoscrolspeed={autoscrolspeed}
                reviews={reviews}
                backgroundcolor={backgroundcolor}
                cardbordercolor={cardbordercolor}
                starcolor={starcolor}
                textcolor={textcolor}
                nooflines={nooflines}
                verifiedbadgecolor={verifiedbadgecolor}
                reviewStats={reviewStats}
                reviewCount={reviewCount}
              />
            ) : (
              <div className="slider-wrapper-8">
                <div className="d-flex flex-column gap-1">
                  <h2
                    className={`carouselTitle ${xToken === "versusforher-mean3.myshopify.com" &&
                      "versus-slider"
                      }`}
                    style={{
                      fontFamily:
                        xToken === "ecs-website.myshopify.com" ||
                          xToken === "versusforher-mean3.myshopify.com"
                          ? "Poppins, sans-serif"
                          : "",
                      fontSize:
                        xToken === "ecs-website.myshopify.com" ? "18px" : "",
                      fontWeight:
                        xToken === "ecs-website.myshopify.com" ? "700" : "",
                    }}
                  >
                    {xToken !== "versusforher-mean3.myshopify.com" &&
                      carouselTitle}
                    {xToken === "versusforher-mean3.myshopify.com" && (
                      <span>{carouselTitle}</span>
                    )}
                  </h2>
                  <p className="carousel-sub-heading">{carouselSubtitle}</p>
                </div>
                <>
                  <Swiper
                    navigation
                    slidesPerView={1}
                    spaceBetween={42}
                    // centeredSlides={true}
                    // centeredSlidesBounds={true}
                    autoplay={
                      autoscroll
                        ? {
                          delay: autoscrolspeed ?? 5000,
                        }
                        : false
                    }
                    modules={[Navigation, Autoplay]}
                    initialSlide="1"
                    // loop={true}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                        centeredSlides: false,
                      },

                      450: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                        centeredSlides: false,
                      },
                      550: {
                        slidesPerView: 1,
                        spaceBetween: 24,
                        centeredSlides: false,
                      },

                      768: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                        centeredSlides: false,
                      },
                      1000: {
                        slidesPerView: 4,
                        spaceBetween: 24,
                        centeredSlides: false,
                      },

                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        centeredSlides: false,
                      },
                      1440: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                      },
                      1920: {
                        slidesPerView: 5,
                        spaceBetween: 40,
                      },
                    }}
                    className="slider_8"
                  >
                    {reviews?.docs?.map((item, index) => (
                      <SwiperSlide key={index} style={{ borderRadius: xToken === "versusforher-mean3.myshopify.com" ? "12px" : "" }}>
                        <div
                          class="slide-content c-pointer"
                          style={{
                            background: backgroundcolor,
                            border: "1px solid",
                            borderColor: cardbordercolor,
                            borderRadius: xToken === "versusforher-mean3.myshopify.com" ? "12px" : ""
                          }}
                          onClick={() => {
                            setTimeout(
                              () =>
                                window.parent.postMessage(
                                  { reviewId: item._id },
                                  "*"
                                ),
                              200
                            )
                          }}
                        >
                          <div class="top-section">
                            <div class="customer-reviews">
                              <div class="happy-customer-stars">
                                {Array(item.reviewRating)
                                  .fill()
                                  .map((_, i) => (
                                    <div className="star">
                                      <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                          fill={starcolor}
                                          stroke={starcolor}
                                        />
                                      </svg>
                                    </div>
                                  ))}
                                {Array(5 - (item?.reviewRating ?? 0))
                                  .fill()
                                  .map((_, i) => (
                                    <div className="star">
                                      <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                          fill="#D3D3D3"
                                          stroke="#D3D3D3"
                                        />
                                      </svg>
                                    </div>
                                  ))}
                              </div>
                              <span
                                class="days-ago"
                                style={{ color: textcolorHalf }}
                              >
                                {formatDate(item.createdAt)}
                              </span>
                            </div>
                            <p
                              class="title text-start"
                              style={{ color: textcolor }}
                            >
                              {item.reviewDetails?.length > 25
                                ? `${item.reviewDetails?.substring(0, 25)}...`
                                : item.reviewDetails}
                            </p>
                            <p class="description" style={{ color: textcolor }}>
                              {item.reviewDescription?.length > nooflines
                                ? `${item.reviewDescription?.substring(
                                  0,
                                  nooflines
                                )}...`
                                : item.reviewDescription}
                            </p>
                          </div>

                          <div class="bottom-section d-flex gap-1 align-items-center">
                            <h4
                              class="customer-name"
                              style={{ color: textcolor }}
                            >
                              {getFirstNameAndLastInitial(item.reviewerName)}
                            </h4>
                            {item.isAuthorized ? (
                              <div className="verified-customer">
                                <svg
                                  width="12"
                                  height="14"
                                  viewBox="0 0 12 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                    fill={verifiedbadgecolor}
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                    fill="white"
                                  />
                                </svg>
                                {/* <span
                                className="text"
                                style={{ color: textcolor }}
                              >
                                verified customer
                              </span> */}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}

                  </Swiper>


                  {/* <div className="verified-bottom-badge verified-section d-flex flex-column">
                  <span>
                    {getAverageRating(reviewStats, reviewCount)} out of 5 based
                    on <b>{reviewCount}&nbsp;Reviews</b>{" "}
                  </span>
                  <span>
                    Powered by&nbsp;
                    <a target="_blank" href="https://quoli.io">
                      <QuoliVerifyIcon />
                    </a>
                  </span>
                </div> */}
                </>
                {/* <PoweredByQuoli /> */}
              </div>
            )}
          </>
        ) : //this carousel same like slider issue
          carouselSelected === "8" ? (
            <>
              <div className="slider-wrapper-9">
                <div className="d-flex flex-column gap-1">
                  <h2 className="carouselTitle">{carouselTitle}</h2>
                  <p className="carousel-sub-heading">{carouselSubtitle}</p>
                </div>
                <>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Swiper
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-next"
                      }}
                      slidesPerView={3}
                      spaceBetween={42}
                      // centeredSlides={true}
                      // centeredSlidesBounds={true}
                      autoplay={
                        autoscroll
                          ? {
                            delay: autoscrolspeed ?? 5000,
                          }
                          : false
                      }
                      modules={[Navigation, Autoplay]}
                      initialSlide="2"
                      loop={true}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                          centeredSlides: true,
                        },

                        450: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                          centeredSlides: true,
                        },
                        550: {
                          slidesPerView: 1,
                          spaceBetween: 24,
                          centeredSlides: true,
                        },

                        768: {
                          slidesPerView: 3,
                          spaceBetween: 24,
                          centeredSlides: false,
                        },
                        1000: {
                          slidesPerView: 3,
                          spaceBetween: 24,
                          centeredSlides: false,
                        },

                        1200: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        1440: {
                          slidesPerView: 5,
                          spaceBetween: 30,
                        },
                        1920: {
                          slidesPerView: 5,
                          spaceBetween: 40,
                        },
                      }}
                      className="slider_9"
                    >
                      {reviews?.docs?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div
                            class="slide-content c-pointer"
                            style={{
                              background: backgroundcolor,
                              border: "1px solid",
                              borderColor: cardbordercolor,
                            }}
                            onClick={() => {
                              setTimeout(
                                () =>
                                  window.parent.postMessage(
                                    { reviewId: item._id },
                                    "*"
                                  ),
                                200
                              )
                            }}
                          >
                            <div class="top-section">
                              <div class="customer-reviews d-flex justify-content-center">
                                <div class="happy-customer-stars d-flex-">
                                  {Array(item.reviewRating)
                                    .fill()
                                    .map((_, i) => (
                                      <div className="star">
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                            fill={starcolor}
                                            stroke={starcolor}
                                          />
                                        </svg>
                                      </div>
                                    ))}
                                  {Array(5 - (item?.reviewRating ?? 0))
                                    .fill()
                                    .map((_, i) => (
                                      <div className="star">
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                            fill="#D3D3D3"
                                            stroke="#D3D3D3"
                                          />
                                        </svg>
                                      </div>
                                    ))}
                                </div>
                                {/* <span class="days-ago" style={{ color: textcolor }}>
                              reejhejh
                            </span> */}
                              </div>
                              <div class="d-flex flex-column gap-3">
                                <p
                                  class="title text-start"
                                  style={{ color: textcolor }}
                                >
                                  {item.reviewDetails?.length > 25
                                    ? `${item.reviewDetails?.substring(0, 25)}...`
                                    : item.reviewDetails}
                                </p>
                                <p class="description" style={{ color: textcolor }}>
                                  {item.reviewDescription?.length > nooflines
                                    ? `${item.reviewDescription?.substring(
                                      0,
                                      nooflines
                                    )}...`
                                    : item.reviewDescription}
                                </p>
                              </div>
                            </div>

                            <div>
                              <div class="bottom-section d-flex gap-1 align-items-center justify-content-center flex-column">
                                <h4
                                  class="customer-name d-flex gap-2"
                                  style={{ color: textcolor }}
                                >
                                  {item.reviewerName}
                                  {item.isAuthorized ? (
                                    <div className="verified-customer">
                                      <svg
                                        width="12"
                                        height="14"
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                          fill={verifiedbadgecolor}
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                          fill="white"
                                        />
                                      </svg>
                                      {/* <span
                                className="text"
                                style={{ color: textcolor }}
                              >
                                verified customer
                              </span> */}

                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </h4>

                                <div className="d-flex justify-content-center w-100 pt-1">
                                  <span
                                    class="days-ago"
                                    style={{
                                      color: textcolorHalf,
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatDate(item.createdAt)}
                                  </span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                  </div>
                  {/* <div className="verified-bottom-badge verified-section d-flex flex-column">
                  <span>
                    {getAverageRating(reviewStats, reviewCount)} out of 5 based
                    on <b>{reviewCount}&nbsp;Reviews</b>{" "}
                  </span>
                  <span>
                    Powered by&nbsp;
                    <a target="_blank" href="https://quoli.io">
                      <QuoliVerifyIcon />
                    </a>
                  </span>
                </div> */}
                </>
                {/* <PoweredByQuoli /> */}
              </div>
            </>
          ) : //carousel 9 slider issue
            carouselSelected === "11" ? (
              <div>
                <div className="slider-wrapper-10 d-flex- flex-column gap-5">
                  <div className="d-flex flex-column gap-1">
                    <h2 className="carouselTitle">{carouselTitle}</h2>
                    <p className="carousel-sub-heading">{carouselSubtitle}</p>
                  </div>

                  <>
                    <Swiper
                      navigation={true}
                      slidesPerView={5}
                      spaceBetween={42}
                      autoplay={
                        autoscroll
                          ? {
                            delay: autoscrolspeed ?? 5000,
                          }
                          : false
                      }
                      initialSlide={"3"}
                      loop={true}
                      // centeredSlides={true}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        300: {
                          slidesPerView: 1.5,
                          spaceBetween: 24,
                          centeredSlides: true,
                          initialSlide: "3",
                        },
                        375: {
                          slidesPerView: 1.5,
                          spaceBetween: 24,
                          centeredSlides: true,
                          initialSlide: "3",
                        },

                        550: {
                          slidesPerView: 2.2,
                          spaceBetween: 24,
                          centeredSlides: true,
                          initialSlide: "3",
                        },

                        768: {
                          slidesPerView: 3.5,
                          spaceBetween: 24,
                          centeredSlides: true,
                        },
                        1000: {
                          slidesPerView: 4,
                          spaceBetween: 24,
                          centeredSlides: true,
                        },

                        1200: {
                          slidesPerView: 4.8,
                          spaceBetween: 10,
                        },
                        1440: {
                          slidesPerView: 6,
                          spaceBetween: 10,
                        },
                        1920: {
                          slidesPerView: 6,
                          spaceBetween: 10,
                        },
                      }}
                      className="slider_10"
                    >
                      {reviews?.docs?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div
                            class="slide-content c-pointer"
                            style={{}}
                            onClick={() => {
                              setTimeout(
                                () =>
                                  window.parent.postMessage(
                                    { reviewId: item._id },
                                    "*"
                                  ),
                                200
                              )
                            }}
                          >
                            {item?.images && item?.images[0]?.mediaType === 2 ? (
                              <img
                                src={getThumbnailImageUrl(item?.images[0]?.mediaUrl)}
                                class="happy-customer-img"
                                alt="product"
                                width={250}
                              />
                            ) : (
                              <video
                                src={
                                  item?.images
                                    ? getImageUrl(item?.images[0]?.mediaUrl)
                                    : ""
                                }
                                class="happy-customer-img"
                                alt="product"
                                width={250}
                              />
                            )}
                            <div
                              class="bottom-section"
                              style={{
                                borderTop: "0px",
                                // borderColor: card_border_color,
                                borderTopColor: "transparent",
                                borderRadius: "10px",
                                padding: "20px",
                              }}
                            >
                              <div class="happy-customer-stars">
                                {Array(item?.reviewRating)
                                  .fill()
                                  .map((_, i) => (
                                    <div class="star" key={i}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_18_36387)">
                                          <path
                                            d="M10.2076 2.69137C10.3972 2.37631 10.8539 2.37631 11.0435 2.69137L13.5642 6.87991C13.6324 6.9931 13.7435 7.07382 13.8722 7.10362L18.6346 8.20663C18.9929 8.28959 19.134 8.72399 18.893 9.00167L15.6884 12.6933C15.6018 12.7931 15.5593 12.9237 15.5708 13.0553L15.9934 17.9256C16.0252 18.2919 15.6557 18.5604 15.3171 18.4169L10.8159 16.5099C10.6942 16.4584 10.5569 16.4584 10.4353 16.5099L5.934 18.4169C5.59543 18.5604 5.2259 18.2919 5.2577 17.9256L5.68037 13.0553C5.69179 12.9237 5.64935 12.7931 5.56276 12.6933L2.35815 9.00167C2.11711 8.72399 2.25825 8.28959 2.61648 8.20663L7.37897 7.10362C7.50766 7.07382 7.61876 6.9931 7.68688 6.87991L10.2076 2.69137Z"
                                            fill="white"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_18_36387">
                                            <rect
                                              width="19.5134"
                                              height="19.5134"
                                              fill="white"
                                              transform="translate(0.86731 0.648438)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                  ))}
                              </div>
                              <div className="d-flex gap-1 align-items-center">
                                <p class="description" style={{ color: "#ffff" }}>
                                  {item?.reviewerName}
                                </p>

                                <span>
                                  {item.isAuthorized && (
                                    <div className="verified-customer">
                                      <svg
                                        width="12"
                                        height="14"
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                          fill="white"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                          fill="black"
                                        />
                                      </svg>
                                      {/* <span className="text" style={{ color: textcolor }}>
                              verified customer
                            </span> */}
                                    </div>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                </div>
              </div>
            ) : // this carousel working fine.. below qa pending..
              carouselSelected === "9" ? (
                <>
                  <div className="slider-wrapper-12">
                    <div className="d-flex flex-column gap-1">
                      <h2 className="carouselTitle">{carouselTitle}</h2>
                      <p className="carousel-sub-heading">{carouselSubtitle}</p>
                    </div>
                    <div className="verified-bottom-badge verified-section d-flex pb-5">
                      <span className="rating-count-12">
                        {getAverageRating(reviewStats, reviewCount)}
                      </span>

                      {/* <span>
                Powered by&nbsp;
                <a target="_blank" href="https://quoli.io">
                  <QuoliVerifyIcon />
                </a>
              </span> */}
                      <div class="happy-customer-stars">
                        {/* <RatingStars
                          rating={getAverageRating(reviewStats, reviewCount)}
                          color={starcolor ?? "#EB2136"}
                        /> */}
                        {Array(
                          parseInt(getAverageRating(reviewStats, reviewCount) ?? 0) ?? 0
                        )
                          .fill()
                          .map((_, i) => (
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 47 47"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_667_44644)">
                                <path
                                  d="M22.5879 4.8564C23.0386 4.10748 24.1244 4.10748 24.5751 4.8564L30.5672 14.8132C30.7291 15.0822 30.9932 15.2741 31.2991 15.345L42.6203 17.967C43.4718 18.1642 43.8073 19.1968 43.2343 19.8569L35.6165 28.6325C35.4107 28.8697 35.3098 29.1802 35.3369 29.493L36.3417 41.0703C36.4173 41.9411 35.5389 42.5793 34.734 42.2383L24.0339 37.7052C23.7447 37.5827 23.4183 37.5827 23.1291 37.7052L12.429 42.2383C11.6241 42.5793 10.7457 41.9411 10.8213 41.0703L11.8261 29.493C11.8532 29.1802 11.7523 28.8697 11.5465 28.6325L3.92867 19.8569C3.35567 19.1968 3.6912 18.1642 4.54275 17.967L15.8639 15.345C16.1698 15.2741 16.4339 15.0822 16.5958 14.8132L22.5879 4.8564Z"
                                  fill={starcolor}
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_667_44644">
                                  <rect
                                    width="46.3861"
                                    height="46.3861"
                                    fill={starcolor}
                                    transform="translate(0.385986)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          ))}
                        {getAverageRating(reviewStats, reviewCount) -
                          Math.floor(getAverageRating(reviewStats, reviewCount)) >
                          0 && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_18_35991)">
                                <path
                                  d="M12.487 2.76354C12.7299 2.35991 13.3151 2.35991 13.558 2.76354L16.7874 8.1298C16.8747 8.27481 17.017 8.37823 17.1819 8.41641L23.2835 9.82955C23.7424 9.93584 23.9233 10.4924 23.6144 10.8481L19.5088 15.5778C19.3978 15.7056 19.3435 15.8729 19.3581 16.0416L19.8996 22.2812C19.9404 22.7505 19.4669 23.0945 19.0332 22.9107L13.2663 20.4675C13.1104 20.4015 12.9345 20.4015 12.7787 20.4675L7.01176 22.9107C6.57798 23.0945 6.10456 22.7505 6.14529 22.2812L6.68681 16.0416C6.70144 15.8729 6.64707 15.7056 6.53613 15.5778L2.43047 10.8481C2.12165 10.4924 2.30249 9.93584 2.76143 9.82955L8.86301 8.41641C9.02788 8.37823 9.17023 8.27481 9.25749 8.1298L12.487 2.76354Z"
                                  fill="#D3D3D3"
                                />
                                <path
                                  d="M12.5119 2.7222C12.6556 2.48339 13.0225 2.58528 13.0225 2.864L13.0225 8.35667V11.1871V13.8833V17.8051L13.0225 19.9503C13.0225 20.2012 12.8724 20.4278 12.6413 20.5257L7.01183 22.9107C6.57806 23.0945 6.10464 22.7505 6.14537 22.2812L6.68689 16.0416C6.70152 15.8729 6.64715 15.7056 6.53621 15.5778L2.43055 10.8481C2.12173 10.4924 2.30256 9.93584 2.76151 9.82955L8.86308 8.41641C9.02796 8.37823 9.1703 8.27481 9.25757 8.1298L12.5119 2.7222Z"
                                  fill={starcolor}
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_18_35991">
                                  <rect
                                    width="25"
                                    height="25"
                                    fill="white"
                                    transform="translate(0.522949 0.145996)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          )}

                        {Array(
                          5 -
                          Math.ceil(
                            getAverageRating(reviewStats, reviewCount) ?? 0
                          ) ?? 0
                        )
                          .fill()
                          .map((_, i) => (
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 47 47"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_667_44648)">
                                <path
                                  d="M22.3565 4.8564C22.8072 4.10748 23.8929 4.10748 24.3437 4.8564L30.3357 14.8132C30.4977 15.0822 30.7618 15.2741 31.0677 15.345L42.3888 17.967C43.2404 18.1642 43.5759 19.1968 43.0029 19.8569L35.3851 28.6325C35.1792 28.8697 35.0784 29.1802 35.1055 29.493L36.1103 41.0703C36.1858 41.9411 35.3074 42.5793 34.5026 42.2383L23.8024 37.7052C23.5133 37.5827 23.1868 37.5827 22.8977 37.7052L12.1975 42.2383C11.3927 42.5793 10.5143 41.9411 10.5899 41.0703L11.5946 29.493C11.6218 29.1802 11.5209 28.8697 11.315 28.6325L3.69722 19.8569C3.12423 19.1968 3.45975 18.1642 4.3113 17.967L15.6324 15.345C15.9383 15.2741 16.2025 15.0822 16.3644 14.8132L22.3565 4.8564Z"
                                  fill="#D3D3D3"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_667_44648">
                                  <rect
                                    width="46.3861"
                                    height="46.3861"
                                    fill="white"
                                    transform="translate(0.158203)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          ))}
                      </div>
                      <div className="rating-count-12">{reviewCount} Reviews</div>
                    </div>
                    <>
                      <div className="d-flex gap-5 main-div" style={{ width: "100%" }}>
                        <div
                          className="mobile-response-rating-box"
                          style={{ width: "49%", position: "relative", minHeight: "470px" }}
                        >
                          <Swiper
                            navigation={true}
                            slidesPerView={1}
                            spaceBetween={0}
                            // centeredSlides={true}
                            // centeredSlidesBounds={true}
                            // loop={true}
                            autoplay={
                              autoscroll
                                ? {
                                  delay: autoscrolspeed ?? 5000,
                                }
                                : false
                            }
                            modules={[Navigation, Autoplay]}
                            breakpoints={{
                              0: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                                centeredSlides: true,
                              },

                              450: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                                centeredSlides: true,
                              },
                              550: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                                centeredSlides: true,
                              },

                              768: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                                centeredSlides: true,
                              },
                              1000: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                                centeredSlides: false,
                              },

                              1200: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                              },
                              1440: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                              },
                              1920: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                              },
                            }}
                            className="slider_12"
                          >
                            {reviews?.docs?.map((item, index) => (
                              <SwiperSlide key={index}>
                                <div
                                  class="slide-content c-pointer"
                                  style={{
                                    background: backgroundcolor,
                                    border: "1px solid",
                                    borderColor: cardbordercolor,
                                    padding: "25px 15px",
                                  }}
                                  onClick={() => {
                                    setTimeout(
                                      () =>
                                        window.parent.postMessage(
                                          { reviewId: item._id },
                                          "*"
                                        ),
                                      200
                                    )
                                  }}
                                >
                                  <div class="top-section">
                                    <div class="customer-reviews d-flex ">
                                      <div class="happy-customer-stars d-flex-">
                                        {Array(item.reviewRating)
                                          .fill()
                                          .map((_, i) => (
                                            <div>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="17"
                                                viewBox="0 0 25 25"
                                                fill={starcolor ?? "none"}
                                                stroke={starcolor}
                                                strokeWidth="1.5"
                                              >
                                                <path
                                                  d="M12.5275 0.660156L15.6905 9.11913L24.7129 9.51337L17.6453 15.1355L20.0585 23.8382L12.5275 18.8539L4.99646 23.8382L7.40959 15.1355L0.342053 9.51337L9.36444 9.11913L12.5275 0.660156Z"
                                                  fill={starcolor}
                                                  stroke={starcolor}
                                                />
                                              </svg>
                                              {/* <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                          fill={starcolor}
                                          stroke={starcolor}
                                        />
                                      </svg> */}
                                            </div>
                                          ))}
                                        {Array(5 - item.reviewRating)
                                          .fill()
                                          .map((_, i) => (
                                            <div>
                                              {/* <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                          stroke={starcolor}
                                        />
                                      </svg> */}
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="17"
                                                viewBox="0 0 25 25"
                                                fill={"white" ?? "none"}
                                                stroke="#D3D3D3"
                                                strokeWidth="1.5"
                                              >
                                                <path
                                                  d="M12.5275 0.660156L15.6905 9.11913L24.7129 9.51337L17.6453 15.1355L20.0585 23.8382L12.5275 18.8539L4.99646 23.8382L7.40959 15.1355L0.342053 9.51337L9.36444 9.11913L12.5275 0.660156Z"
                                                  fill="#D3D3D3"
                                                  stroke="#D3D3D3"
                                                />
                                              </svg>
                                            </div>
                                          ))}
                                      </div>
                                      {/* <span class="days-ago" style={{ color: textcolor }}>
                          reejhejh
                        </span> */}
                                    </div>
                                    <div class="carousel-12-top-section">
                                      <p
                                        class="title text-start"
                                        style={{ color: textcolor }}
                                      >
                                        {item.reviewDetails?.length > 25
                                          ? `${item.reviewDetails?.substring(0, 25)}...`
                                          : item.reviewDetails}
                                      </p>
                                      <p
                                        class="description"
                                        style={{ color: textcolor }}
                                      >
                                        {item.reviewDescription?.length > nooflines
                                          ? `${item.reviewDescription?.substring(
                                            0,
                                            nooflines
                                          )}...`
                                          : item.reviewDescription}
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <hr
                                      style={{
                                        border: "1px solid gray",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: "50%" }}>
                                      <div class="bottom-section d-flex gap-1 align-items-center">
                                        <h4
                                          class="customer-name"
                                          style={{ color: textcolor }}
                                        >
                                          {item.reviewerName}
                                        </h4>
                                        {item.isAuthorized ? (
                                          <div className="verified-customer">
                                            <svg
                                              width="12"
                                              height="14"
                                              viewBox="0 0 12 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                                fill={verifiedbadgecolor}
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                                fill="white"
                                              />
                                            </svg>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {/* <span
                                className="reviewer-name-12"
                                style={{ color: textcolor }}
                              >
                                Reviewed from India
                              </span> */}
                                    </div>

                                    <div
                                      className="Date-format-12"
                                      style={{ color: textcolorHalf }}
                                    >
                                      {formatDate(item.createdAt)}
                                    </div>
                                  </div>
                                  {/* <div className="d-flex justify-content-center">
                        <span
                          class="days-ago"
                          style={{ color: textcolor, textAlign: "center" }}
                        >
                          {formatDate(item.createdAt)}
                        </span>
                      </div> */}
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                        <div className="row">
                          <div className="image-container col-12">
                            {reviews?.docs?.slice(0, 6).map((item, index) => (

                              item.images?.length > 0 ? (
                                item.images[0].mediaType === 2 ? (
                                  <img
                                    src={getThumbnailImageUrl(item.images[0].mediaUrl)}
                                    alt="Customer media"
                                    className="image-carousel-11 col-4 p-2"
                                    key={index}
                                    onClick={() => {
                                      setTimeout(
                                        () =>
                                          window.parent.postMessage(
                                            { reviewId: item._id },
                                            "*"
                                          ),
                                        200
                                      )
                                    }}
                                  />
                                ) : (
                                  <>
                                    <video
                                      src={getImageUrl(item.images[0].mediaUrl)}
                                      controls={false}
                                      className="image-carousel-11"
                                    />
                                  </>
                                )
                              ) : null

                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                    {/* <PoweredByQuoli /> */}
                  </div>
                </>
              ) : //this is carousel work fine slider next prev position issue...
                carouselSelected === "10" ? (
                  <>
                    <div className="slider-wrapper-11">
                      <div className="d-flex flex-column gap-1">
                        <h2 className={`carouselTitle ${xToken === "versusforher-mean3.myshopify.com" &&
                          "versus-slider"
                          }`}> {xToken !== "versusforher-mean3.myshopify.com" &&
                            carouselTitle}
                          {xToken === "versusforher-mean3.myshopify.com" && (
                            <span>{carouselTitle}</span>
                          )}</h2>

                        <p className="carousel-sub-heading">{carouselSubtitle}</p>
                      </div>
                      <>
                        <Swiper
                          navigation={true}
                          slidesPerView={0}
                          spaceBetween={70}
                          centeredSlides={true}
                          centeredSlidesBounds={true}
                          autoplay={
                            autoscroll
                              ? {
                                delay: autoscrolspeed ?? 5000,
                              }
                              : false
                          }
                          modules={[Navigation, Autoplay]}
                          initialSlide="1"
                          loop={true}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                              spaceBetween: 30,
                              centeredSlides: true,
                            },

                            450: {
                              slidesPerView: 1,
                              spaceBetween: 30,
                              centeredSlides: true,
                            },
                            550: {
                              slidesPerView: 1,
                              spaceBetween: 24,
                              centeredSlides: false,
                            },

                            768: {
                              slidesPerView: 1,
                              spaceBetween: 24,
                              centeredSlides: false,
                            },
                            1000: {
                              slidesPerView: 1,
                              spaceBetween: 24,
                              centeredSlides: false,
                            },

                            1200: {
                              slidesPerView: 1,
                              spaceBetween: 30,
                            },
                            1440: {
                              slidesPerView: 1,
                              spaceBetween: 30,
                            },
                            1920: {
                              slidesPerView: 1,
                              spaceBetween: 40,
                            },
                          }}
                          className="slider_11"
                        >
                          {reviews?.docs?.map((item, index) => (
                            <div>
                              <SwiperSlide key={index}>
                                <div
                                  class="slide-content c-pointer"
                                  style={{
                                    background: backgroundcolor,
                                    border: "none",
                                    borderColor: cardbordercolor,
                                    width: "84%",
                                  }}
                                  onClick={() => {
                                    setTimeout(
                                      () =>
                                        window.parent.postMessage(
                                          { reviewId: item._id },
                                          "*"
                                        ),
                                      200
                                    )
                                  }}
                                >
                                  <div class="top-section">
                                    <div class="customer-reviews d-flex justify-content-center">
                                      <div class="happy-customer-stars d-flex-">
                                        {Array(item.reviewRating)
                                          .fill()
                                          .map((_, i) => (
                                            <div className="star">
                                              <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                                  fill={starcolor}
                                                  stroke={starcolor}
                                                />
                                              </svg>
                                            </div>
                                          ))}
                                        {Array(5 - (item?.reviewRating ?? 0))
                                          .fill()
                                          .map((_, i) => (
                                            <div className="star">
                                              <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8.09336 0.636821C8.28117 0.324748 8.73361 0.324749 8.92142 0.636821L11.4183 4.78574C11.4857 4.89785 11.5958 4.97781 11.7233 5.00733L16.4407 6.0999C16.7955 6.18208 16.9353 6.61237 16.6966 6.88742L13.5223 10.5442C13.4365 10.643 13.3945 10.7723 13.4058 10.9027L13.8245 15.7269C13.856 16.0897 13.4899 16.3557 13.1546 16.2136L8.69589 14.3247C8.57541 14.2736 8.43937 14.2736 8.31889 14.3247L3.86022 16.2136C3.52485 16.3557 3.15882 16.0897 3.19031 15.7269L3.60898 10.9027C3.6203 10.7723 3.57826 10.643 3.49249 10.5442L0.3182 6.88742C0.0794375 6.61237 0.219248 6.18208 0.574082 6.0999L5.29151 5.00733C5.41898 4.97781 5.52904 4.89785 5.59651 4.78574L8.09336 0.636821Z"
                                                  fill="#D3D3D3"
                                                  stroke="#D3D3D3"
                                                />
                                              </svg>
                                            </div>
                                          ))}
                                      </div>
                                      {/* <span class="days-ago" style={{ color: textcolor }}>
                          reejhejh
                        </span> */}
                                    </div>
                                    <div class="d-flex flex-column gap-3">
                                      <p
                                        class="title text-start"
                                        style={{ color: textcolor }}
                                      >
                                        {item.reviewDetails?.length > 25
                                          ? `${item.reviewDetails?.substring(0, 25)}...`
                                          : item.reviewDetails}
                                      </p>
                                      <p class="description" style={{ color: textcolor }}>
                                        {item.reviewDescription?.length > nooflines
                                          ? `${item.reviewDescription?.substring(
                                            0,
                                            nooflines
                                          )}...`
                                          : item.reviewDescription}
                                      </p>
                                    </div>
                                  </div>

                                  <div class="bottom-section d-flex gap-1 align-items-center justify-content-center">
                                    <h4
                                      class="customer-name"
                                      style={{ color: textcolor }}
                                    >
                                      {item.reviewerName}
                                    </h4>
                                    {item.isAuthorized ? (
                                      <div className="verified-customer">
                                        <svg
                                          width="12"
                                          height="14"
                                          viewBox="0 0 12 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                                            fill={verifiedbadgecolor}
                                          />
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                                            fill="white"
                                          />
                                        </svg>
                                        {/* <span
                            className="text"
                            style={{ color: textcolor }}
                          >
                            verified customer
                          </span> */}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {/* <div className="d-flex justify-content-center">
                        <span
                          class="days-ago"
                          style={{ color: textcolor, textAlign: "center" }}
                        >
                          {formatDate(item.createdAt)}
                        </span>
                      </div> */}
                                </div>
                              </SwiperSlide>
                            </div>
                          ))}
                        </Swiper>
                        {/* <div className="verified-bottom-badge verified-section d-flex flex-column">
                <span>
                  {getAverageRating(reviewStats, reviewCount)} out of 5 based
                  on <b>{reviewCount}&nbsp;Reviews</b>{" "}
                </span>
                <span>
                  Powered by&nbsp;
                  <a target="_blank" href="https://quoli.io">
                    <QuoliVerifyIcon />
                  </a>
                </span>
              </div> */}
                      </>
                      {/* <PoweredByQuoli /> */}
                    </div>
                  </>
                ) : (
                  <div className="not-activated">Widget not activated</div>
                )
      }
    </div >
  )
}

export default Sliders
